import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { IntlProvider } from "react-intl";

import useConfig from "hooks/useConfig";

// load locales files
const loadLocaleData = () => {
  return import("utils/locales/en.json");
};

/**
 * A component for managing internationalization (i18n) and localization (l10n) of the application.
 * Loads locale data and provides translated messages to child components using React Intl.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components to be rendered.
 * @returns {React.ReactNode} The localized component.
 */
const Locales = ({ children }) => {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(i18n).then((d) => {
      setMessages(d.default);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};

Locales.propTypes = {
  children: PropTypes.node,
};

export default Locales;
