import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { APP_DEFAULT_PATH } from "config";
import useAuth from "hooks/useAuth";

/**
 * A component that guards routes for guest users.
 * If a user is logged in, it redirects them to the default path.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components to be rendered.
 * @returns {React.ReactNode} The guarded component.
 */
const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: {
          from: "",
        },
        replace: true,
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
