/**
 * Redux reducer for authentication state management.
 */
import { REGISTER, LOGIN, LOGOUT } from "./actions";

/**
 * Initial state for the authentication reducer.
 */
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

/**
 * Authentication reducer function.
 * @param {Object} state - Current authentication state.
 * @param {Object} action - Redux action.
 * @returns {Object} New state after applying the action.
 */
const auth = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
