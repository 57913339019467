// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// project import
import { getFirestore } from "firebase/firestore";
import { getStorage, getDownloadURL, uploadBytes, ref } from "firebase/storage";

var app = null;

const config = require("./googleFirebaseConfig.json");

/**
 * Initializes Firebase app if not already initialized.
 */
if (!firebase.apps.length) {
  app = firebase.initializeApp(config);
}

/**
 * Firestore instance for Firebase.
 */
export const firestore = getFirestore(app);

/**
 * Storage instance for Firebase.
 */
export const storage = getStorage(app);

/**
 * Get the download URL for the invoice with the given ID.
 * @param {string} id - Invoice ID.
 * @returns {Promise<string>} - Download URL for the invoice PDF.
 */
export const getInvoiceURL = (id) => {
  return getDownloadURL(ref(storage, "invoices/" + id + "_invoice.pdf"));
};

/**
 * Uploads an invoice file to Firebase storage.
 * @param {string} id - Invoice ID.
 * @param {File} file - Invoice file to upload.
 */
export const uploadInvoice = (id, file) => {
  if (file == null) return;
  uploadBytes(ref(storage, "invoices/" + id + "_invoice.pdf"), file);
};
