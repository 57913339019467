import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "hooks/useAuth";

/**
 * A component that guards routes based on user authentication status.
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components to be rendered.
 * @returns {React.ReactNode} The guarded component.
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  console.log(isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("login", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }
  }, [isLoggedIn, navigate, location]);

  return isLoggedIn ? children : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
