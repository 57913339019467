import { createSlice } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "firebaseConfig";

/**
 * Initial state for the user slice.
 */
const initialState = {
  users: [],
  user: null,
  error: null,
};

/**
 * Redux slice for user state management.
 * @type {Slice}
 */
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAll(state, action) {
      state.users = action.payload;
    },

    get(state, action) {
      state.user = action.payload;
    },

    create(state, action) {
      state.users = [...state.users, action.payload];
    },

    update(state, action) {
      const { newUser } = action.payload;
      const userUpdate = state.users.map((item) => {
        if (item.id === newUser.id) {
          return newUser;
        }
        return item;
      });
      state.users = userUpdate;
    },

    deleteUser(state, action) {
      const { userId } = action.payload;
      const deleteUser = state.users.filter((user) => user.id !== userId);
      state.users = deleteUser;
    },
  },
});

export default user.reducer;

export const { getAll } = user.actions;

/**
 * Thunk action to fetch users from Firestore.
 * @function
 * @returns {Function} Thunk function.
 */
export const getUsers = () => {
  return async (dispatch) => {
    try {
      const ref = collection(firestore, "users");
      const querySnapshot = await getDocs(ref);
      const users = querySnapshot.docs.map((doc) => doc.data());
      dispatch(user.actions.getAll(users));
    } catch (error) {
      console.error(error);
      dispatch(user.actions.hasError(error));
    }
  };
};

export function getUserInsert(userId) {
  throw new Error("Error: getUserInsert not implemented");
}

export function getUserUpdate(userId) {
  throw new Error("Error: getUserUpdate not implemented");
}

export function getUserDelete(userId) {
  throw new Error("Error: getUserDelete not implemented");
}

/**
 * Thunk action to fetch a single user from Firestore by ID.
 * @function
 * @param {string} userId - ID of the user to fetch.
 * @returns {Function} Thunk function.
 */
export const getUser = (userId) => {
  return async (dispatch) => {
    try {
      const ref = collection(firestore, "users");
      const querySnapshot = await getDocs(ref);
      const users = querySnapshot.docs.map((doc) => doc.data());
      const targetUser = users.find((user) => user.id === userId);
      dispatch(user.actions.get(targetUser));
    } catch (error) {
      console.error(error);
      dispatch(user.actions.hasError(error));
    }
  };
};
