import { Stack, Typography } from "@mui/material";

/**
 * Footer component for the application.
 * Displays copyright information.
 * @returns {React.ReactNode} The footer component.
 */
const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: "24px 16px 0px", mt: "auto" }}
  >
    <Typography variant="caption">&copy; Joose Lohi Business Solutions OÜ</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center"></Stack>
  </Stack>
);

export default Footer;
