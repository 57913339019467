import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from "react-redux";

import reducers from "./reducers";

/**
 * Configures and creates a Redux store using provided reducers.
 * @type {import("@reduxjs/toolkit").EnhancedStore}
 */
const store = configureStore({
  reducer: reducers,
});

/**
 * Dispatch function extracted from the Redux store.
 * @type {import("@reduxjs/toolkit").EnhancedStore["dispatch"]}
 */
const { dispatch } = store;

/**
 * Custom hook to access the Redux dispatch function.
 * @returns {import("@reduxjs/toolkit").EnhancedStore["dispatch"]}
 */
const useDispatch = () => useAppDispatch();

/**
 * Custom hook to select data from the Redux store state.
 * @type {import("react-redux").TypedUseSelectorHook}
 */
const useSelector = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
