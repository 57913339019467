import { createSlice } from "@reduxjs/toolkit";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { dispatch } from "store";

/**
 * Initial state for the sale slice.
 */
const initialState = {
  sales: [],
  sale: null,
  error: null,
};

/**
 * Redux slice for managing sales.
 * @type {Slice}
 */
const sale = createSlice({
  name: "sale",
  initialState,
  reducers: {
    getAll(state, action) {
      state.sales = action.payload;
    },

    get(state, action) {
      state.sale = action.payload;
    },

    create(state, action) {
      state.sales = [...state.sales, action.payload];
    },

    update(state, action) {
      const { newSale } = action.payload;
      const saleUpdate = state.sales.map((item) => {
        if (item.id === newSale.id) {
          return newSale;
        }
        return item;
      });
      state.sales = saleUpdate;
    },

    delete(state, action) {
      const { saleId } = action.payload;
      const deleteSale = state.sales.filter((sale) => sale.id !== saleId);
      state.sales = deleteSale;
    },
  },
});

export default sale.reducer;

export const { getAll } = sale.actions;
/**
 * Function to subscribe to sales updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToSales = () => {
  const unsubscribe = onSnapshot(collection(firestore, "sales"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(sale.actions.getAll(data));
  });

  return unsubscribe;
};

/**
 * Function to create a new sale in Firestore.
 * @async
 * @param {object} sale - The sale object to be created.
 */
export async function createSale(sale) {
  try {
    const docRef = doc(collection(firestore, "sales"), sale.id);
    await setDoc(docRef, sale);
  } catch (error) {
    console.error(error);
  }
}
