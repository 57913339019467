// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";

// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";

// auth-provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { subscribeToOrders } from "store/reducers/order";
import { subscribeToSales } from "store/reducers/sale";
import { subscribeToDataRevenue } from "store/reducers/data";

/**
 * Application component that serves as the entry point.
 * @function
 * @returns {JSX.Element} Rendered component.
 */
const App = () => {
  const dispatch = useDispatch();
  const unsubscribeDataRevenue = useRef(null);
  const unsubscribeOrders = useRef(null);
  const unsubscribeSales = useRef(null);

  useEffect(() => {
    // Effect to subscribe to data revenue, orders, and sales on component mount
    unsubscribeDataRevenue.current = subscribeToDataRevenue();
    unsubscribeOrders.current = subscribeToOrders();
    unsubscribeSales.current = subscribeToSales();

    return () => {
      // Cleanup function to unsubscribe from data revenue, orders, and sales on component unmount
      if (unsubscribeDataRevenue.current) {
        unsubscribeDataRevenue.current();
      }

      if (unsubscribeOrders.current) {
        unsubscribeOrders.current();
      }

      if (unsubscribeSales.current) {
        unsubscribeSales.current();
      }
    };
  }, [dispatch]);

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
