import { dispatch } from "store";
import { createSlice } from "@reduxjs/toolkit";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore } from "firebaseConfig";

/**
 * Initial state for the data slice.
 */
const initialState = {
  dataList: [],
  data: null,
  invoiceNumber: null,
  error: null,
};

/**
 * Redux slice for managing data.
 * @type {Slice}
 */
const data = createSlice({
  name: "data",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload.error;
    },

    getAll(state, action) {
      state.dataList = action.payload;
    },

    getInvoiceNumber(state, action) {
      state.invoiceNumber = action.payload;
    },

    get(state, action) {
      state.data = action.payload;
    },

    create(state, action) {
      state.dataList = [...state.dataList, action.payload];
    },

    update(state, action) {
      const { newData } = action.payload;
      const dataUpdate = state.dataList.map((item) => {
        if (item.id === newData.id) {
          return newData;
        }
        return item;
      });
      state.dataList = dataUpdate;
    },

    delete(state, action) {
      const { invoiceId } = action.payload;
      const deleteData = state.dataList.filter((data) => data.id !== invoiceId);
      state.dataList = deleteData;
    },
  },
});

export default data.reducer;

export const {
  reviewLeadPopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getAll,
  alertPopupToggle,
} = data.actions;

/**
 * Function to subscribe to revenue data updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToDataRevenue = () => {
  const unsubscribe = onSnapshot(
    collection(firestore, `data/revenue/data_${new Date().getFullYear()}`),
    (snapshot) => {
      const revenueData = snapshot.docs.map((doc) => doc.data());
      dispatch(data.actions.getAll(revenueData));
    }
  );
  return unsubscribe;
};

/**
 * Thunk action to fetch invoice number from Firestore.
 * @function
 * @returns {Function} Thunk function.
 */
export const getDataInvoiceNumber = () => {
  return async (dispatch) => {
    try {
      const docRef = doc(firestore, "data/invoice");
      const snapshot = await getDoc(docRef);
      const num = snapshot.data().invoiceNumber;
      dispatch(data.actions.getInvoiceNumber(num));
    } catch (error) {
      dispatch(data.actions.hasError(error));
    }
  };
};
