import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project import
import Loader from "components/Loader";

/**
 * Initial state for authentication context.
 */
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

/**
 * Context for Firebase authentication and services.
 */
const FirebaseContext = createContext(null);

/**
 * Provider component for Firebase authentication and services.
 * @param {object} props - React component props.
 * @param {React.ReactNode} props.children - Child components.
 */
export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                id: user.uid,
                email: user.email,
                name: user.displayName || "Joose Lohi",
                role: "UI/UX Designer",
              },
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * Sign in with email and password.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @returns {Promise<firebase.auth.UserCredential>} - Result of sign-in operation.
   */
  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  /**
   * Sign in with Google.
   * @returns {Promise<firebase.auth.UserCredential>} - Result of sign-in operation.
   */
  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  /**
   * Sign in with Twitter.
   * @returns {Promise<firebase.auth.UserCredential>} - Result of sign-in operation.
   */
  const firebaseTwitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  /**
   * Sign in with Facebook.
   * @returns {Promise<firebase.auth.UserCredential>} - Result of sign-in operation.
   */
  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  /**
   * Register a new user with email and password.
   * @param {string} email - User's email.
   * @param {string} password - User's password.
   * @returns {Promise<firebase.auth.UserCredential>} - Result of registration operation.
   */
  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  /**
   * Sign out the current user.
   * @returns {Promise<void>} - Result of sign-out operation.
   */
  const logout = () => firebase.auth().signOut();

  /**
   * Reset user's password.
   * @param {string} email - User's email.
   * @returns {Promise<void>} - Result of password reset operation.
   */
  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  /**
   * Update user profile.
   */
  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseTwitterSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
