import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Container, Toolbar } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import HorizontalBar from "./Drawer/HorizontalBar";
import { MenuOrientation } from "config";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/reducers/user";
import { openDrawer } from "store/reducers/menu";
import useAuth from "hooks/useAuth";

/**
 * Main layout component for the application.
 * Manages the overall layout structure including header, footer, and main content.
 * @returns {React.ReactNode} The main layout component.
 */
const MainLayout = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { users } = useSelector((state) => state.user);
  const { container, miniDrawer, menuOrientation } = useConfig();
  const theme = useTheme();
  const matchDownXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, [users.length, dispatch]);

  useEffect(() => {
    const currentUser = users.find((u) => u.id === user.id);
    document.title = `Good ${getCurrentDay()} ${currentUser?.firstName}! ${getRandomEmoji()}`;
  }, [users, user.id]);

  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!matchDownXL));
    }
  }, [miniDrawer, matchDownXL, dispatch]);

  const getCurrentDay = () =>
    ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][
      new Date().getDay()
    ];

  const getRandomEmoji = () => ["👋", "🤛", "💪"][Math.floor(Math.random() * 3)];

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}
      <Box component="main" sx={{ width: "calc(100% - 260px)", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : "inherit" }} />
        <Container
          maxWidth={container ? "xl" : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
