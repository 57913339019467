/**
 * Action type: LOGIN
 * Description: Action to initiate a user login.
 */
export const LOGIN = "@auth/LOGIN";

/**
 * Action type: LOGOUT
 * Description: Action to initiate a user logout.
 */
export const LOGOUT = "@auth/LOGOUT";

/**
 * Action type: REGISTER
 * Description: Action to initiate a user registration.
 */
export const REGISTER = "@auth/REGISTER";
