import { createSlice } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "firebaseConfig";
import { dispatch } from "store";

/**
 * Initial state for the order slice.
 */
const initialState = {
  orders: [],
  order: null,
  error: null,
};

/**
 * Redux slice for managing orders.
 * @type {Slice}
 */
const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload.error;
    },

    getAll(state, action) {
      state.orders = action.payload;
    },
  },
});

export default order.reducer;

export const { getAll } = order.actions;

/**
 * Function to subscribe to order updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToOrders = () => {
  const unsubscribe = onSnapshot(collection(firestore, "orders"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(order.actions.getAll(data));
  });

  return unsubscribe;
};

/**
 * Function to create a new order in Firestore.
 * @async
 * @param {object} order - The order object to be created.
 * @param {string} invoiceNumber - The current invoice number.
 */
export async function createOrder(order, invoiceNumber) {
  try {
    const id = "INV-1-" + invoiceNumber;
    const docref = doc(collection(firestore, "orders"), id);
    order.id = id;
    order.statusUpdated = new Date().toLocaleDateString("en-GB").replaceAll("/", ".");
    await setDoc(docref, order);

    // Increment invoice number and convert back to string
    let newInvoiceNumber = parseInt(invoiceNumber) + 1;
    updateDoc(doc(firestore, "data/invoice"), {
      invoiceNumber: newInvoiceNumber.toString(),
    });
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to update an existing order in Firestore.
 * @async
 * @param {object} order - The updated order object.
 */
export async function updateOrder(order) {
  try {
    const docRef = doc(collection(firestore, "orders"), order.id);
    order.statusUpdated = new Date().toLocaleDateString("en-GB").replaceAll("/", ".");
    await updateDoc(docRef, order);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to delete an order from Firestore.
 * @async
 * @param {string} id - The ID of the order to be deleted.
 */
export async function deleteOrder(id) {
  try {
    const docRef = doc(collection(firestore, "orders"), id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error(error);
  }
}
