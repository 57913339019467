import { lazy } from "react";

import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";

/**
 * Lazy-loaded component for authentication login.
 * @type {Function}
 */
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));

/**
 * Routes for login/authentication.
 * @type {object}
 */
const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "login",
          element: <AuthLogin />,
        },
        /*
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
        */
      ],
    },
  ],
};

export default LoginRoutes;
