import { useRoutes } from "react-router-dom";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

/**
 * Component for defining and rendering theme-specific routes.
 * This component uses the useRoutes hook from react-router-dom to handle routing.
 * It combines the MainRoutes and LoginRoutes configurations to provide the complete routing setup.
 * @returns {JSX.Element} The rendered routes.
 */
export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes]);
}
